// src/utils/api.js
import axios from 'axios';
import API_ENDPOINTS from '../config/apiConfig';
import { BASE_URL } from '../config/apiConfig';

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Interceptor for adding authorization tokens if needed
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Example: token from localStorage
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
});

export const getChartOfAccounts = async () => {
    return api.get(API_ENDPOINTS.GET_CHARTOFACCOUNTS).then((response) => response.data);
}

export const getOrgConfig = async () => {
    return api.get(API_ENDPOINTS.GET_ORG_CONFIG).then((response) => response.data);
};

export const getDashboards = async (workspaceId) => {
    return api.get(API_ENDPOINTS.GET_DASHBOARDS(workspaceId)).then((response) => response.data);
};

// Use axios for all API calls
export const getReports = async (workspaceId) => {
    const url = API_ENDPOINTS.GET_REPORTS(workspaceId);
    const response = await api.get(url);
    return response.data; // Axios puts the response data in `data`
};

export const getReportPages = async (reportId, workspaceId) => {
    console.log('Fetching pages for report:', { reportId, workspaceId });
    try {
        const response = await fetch(
            `${BASE_URL}/pbiReportpages?reportId=${reportId}&workspaceId=${workspaceId}`
        );
        if (!response.ok) {
            throw new Error('Failed to fetch report pages');
        }
        const data = await response.json();
        console.log('Report pages response:', data);
        return data;
    } catch (error) {
        console.error('Error fetching report pages:', error);
        throw error;
    }
};

export const getDatasets = async () => {
    return api.get(API_ENDPOINTS.GET_DATASETS).then((response) => response.data);
};

export const postItem = async (data) => {
    return api.post(API_ENDPOINTS.POST_ITEM, data).then((response) => response.data);
};

export const getDashboardEmbedInfo = async (dashboardId) => {
    try {
        console.log('Fetching dashboard embed info for:', dashboardId);

        const orgConfig = await getOrgConfig();
        const workspaceId = orgConfig[0].workspaceId;

        const dashboardResponse = await getDashboards(workspaceId);
        const dashboard = dashboardResponse.value.find(d => d.id === dashboardId);

        if (!dashboard) {
            throw new Error('Dashboard not found');
        }

        return {
            embedUrl: dashboard.embedUrl,
            accessToken: 'YOUR_ACCESS_TOKEN',
            id: dashboard.id
        };

    } catch (error) {
        console.error('API Error:', error);
        console.error('Full error details:', {
            message: error.message,
            stack: error.stack
        });
        throw error;
    }
};

export const getEmbedToken = async (reportId, workspaceId, body) => {
    try {
        console.log('Fetching embed token for report:', { reportId, workspaceId });

        const response = await api.post(API_ENDPOINTS.GET_EMBED_TOKEN, {
            reportId,
            workspaceId,
            ...body
        });

        if (!response.data || !response.data.token) {
            throw new Error('Failed to fetch embed token');
        }

        console.log('Embed token response:', response.data);
        return response.data.token;

    } catch (error) {
        console.error('Error fetching embed token:', error);
        throw error;
    }
};


