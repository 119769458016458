import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './utils/awsConfig';
import { App as SendbirdApp } from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';
import { Toaster } from 'react-hot-toast';
import LoginPage from './views/Pages/LoginPage';
import Admin from './layouts/Admin';
import Auth from './layouts/Auth';
import ProtectedRoute from './components/ProtectedRoute';

const AUTH = Amplify.configure(awsconfig);

function App() {
  const [isChatVisible, setIsChatVisible] = useState(false);

  useEffect(() => {
    console.log('Chat visibility changed:', isChatVisible);
  }, [isChatVisible]);

  const colorSet = {
    '--sendbird-light-primary-500': '#bebcde',
    '--sendbird-light-primary-400': '#bebcde',
    '--sendbird-light-primary-300': '#bebcde',
    '--sendbird-light-primary-200': '#bebcde',
    '--sendbird-light-primary-100': '#bebcde',
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute>
                <Admin 
                  isChatVisible={isChatVisible}
                  setIsChatVisible={setIsChatVisible}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
        </Routes>
      </div>

      {/* Chat Component */}
      {isChatVisible && (
        <div 
          style={{
            position: 'fixed',
            right: '0px',
            bottom: '0px',
            width: '100vw',
            height: '93vh',
            backgroundColor: 'white',
            zIndex: 9999,
            borderRadius: '10px',
            boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SendbirdApp
            appId={'826AF289-DE7F-4676-AE52-089A72C3FA7C'}
            userId={'cust_dev.derekgay'}
            accessToken={'8b0f0f5eca2b756be41b3a8881a12b1eee2501f5'}
            colorSet={colorSet}

          />
        </div>
      )}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 5000,
            style: {
              background: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.3)',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              color: '#ffffff',
              borderRadius: '12px',
              padding: '16px',
            },
            iconTheme: {
              primary: '#f44336',
              secondary: 'rgba(255, 255, 255, 0.7)',
            },
          },
        }}
      />
    </>
  );
}

export default App; 