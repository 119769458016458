import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as pbi from 'powerbi-client';
import { getDashboards, getOrgConfig, getEmbedToken } from "../../utils/api";
import { useContainerSize } from "../../hooks/useContainerSize";
import "./Dashboard.css";

// Add this utility function to parse reportId from URL
const getReportIdFromUrl = (embedUrl) => {
  try {
    const url = new URL(embedUrl);
    const reportId = url.searchParams.get('reportId');
    return reportId;
  } catch (error) {
    console.error('Error parsing report URL:', error);
    return null;
  }
};

function Dashboard() {
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const [workspaceId, setWorkspaceId] = useState(null);
  const [error, setError] = useState(null);
  const { height, width } = useContainerSize();

  useEffect(() => {
    async function fetchWorkspaceId() {
      try {
        const orgConfig = await getOrgConfig();
        console.log("Retrieved workspaceId:", orgConfig[0].workspaceId);
        const newWorkspaceId = orgConfig[0].workspaceId;
        setWorkspaceId(newWorkspaceId);
      } catch (error) {
        console.error("Error in initial setup:", error);
        setError("Failed to fetch workspace ID");
      }
    }

    fetchWorkspaceId();
  }, []); // Only run on mount

  useEffect(() => {
    async function fetchDashboard() {
      if (!workspaceId || !dashboardId) return;

      try {
        const dashboards = await getDashboards(workspaceId);
        console.log("Dashboards:", dashboards);

        // Find the specific dashboard using dashboardId from URL params
        const dashboard = dashboards.value.find(d => d.id === dashboardId);
        
        if (!dashboard) {
          throw new Error(`Dashboard with ID ${dashboardId} not found`);
        }
        
        console.log("Dashboard Info:", dashboard);

        const embedTokenResponse = await getEmbedToken(dashboard.id, workspaceId, {
          embedDetails: { accessLevel: 'View' },
          reportId: dashboard.id,
          workspaceId: workspaceId,
          type: 'dashboards'
        });

        const embedToken = await embedTokenResponse;

        const config = {
          type: 'dashboard',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedToken,
          embedUrl: dashboard.embedUrl,
          id: dashboard.id,
          permissions: pbi.models.Permissions.All,
          ...(width <= 768 && { pageView: "oneColumn" }),

          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false
          }
        };

        const reportContainer = document.getElementById('reportContainer');
        if (reportContainer) {
          const powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
          );

          const dashboardEmbed = powerbi.embed(reportContainer, config);

          // Modified tile click handler
          dashboardEmbed.on('tileClicked', (event) => {
            console.log('Tile clicked:', event);
            
            const reportId = getReportIdFromUrl(event.detail.reportEmbedUrl);
            console.log('Parsed Report ID:', reportId);

            if (reportId) {
              console.log(`Navigating to: /admin/report/${reportId}`);
              navigate(`/admin/report/${reportId}`);
            } else {
              console.error('Could not parse report ID from URL:', event.detail.reportEmbedUrl);
            }
          });

        }
      } catch (error) {
        console.error("Error embedding Power BI dashboard:", error);
        setError(error.message);
      }
    }

    fetchDashboard();
  }, [workspaceId, dashboardId, navigate, height, width]);

  if (error) {
    return (
      <div className="content">
        <h3>Error loading dashboard</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div 
      id="reportContainer" 
      className="report-container report-content "
      style={{ 
        width: '100%',
        padding: '7vh 0 0 0',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    />
  );
}

export default Dashboard;
