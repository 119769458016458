// src/config/apiConfig.js

const BASE_URL = 'https://api.lodgeic.io/v1';

const API_ENDPOINTS = {
    GET_ORG_CONFIG: `${BASE_URL}/orgConfig`,
    GET_REPORTS: (workspaceId) => `${BASE_URL}/pbiReports?workspaceId=${workspaceId}`,
    GET_REPORT_PAGES: (reportId) => `${BASE_URL}/pbiReportPages?reportId=${reportId}`,
    GET_DATASETS: (workspaceId) => `${BASE_URL}/pbiDatasets?workspaceId=${workspaceId}`,
    GET_EMBED_TOKEN: `${BASE_URL}/pbiEmbedToken`,
    GET_DASHBOARDS: (workspaceId) => `${BASE_URL}/pbiDashboards?workspaceId=${workspaceId}`,
    GET_CHARTOFACCOUNTS: `${BASE_URL}/chartOfAccounts`
    // GET_USER
};

export default API_ENDPOINTS;
export { BASE_URL };
