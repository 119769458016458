/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Dashboard from "views/Dashboard/Dashboard.js";
import Report from "views/Reports/Reports.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import ForecastAdjustments from "views/ForecastAdjustments/ForecastAdjustments.js";

function Admin({ isChatVisible, setIsChatVisible }) {
  const toggleChat = () => {
    console.log('Toggling chat from Admin component');
    console.log('Current visibility:', isChatVisible);
    setIsChatVisible(!isChatVisible);
  };

  console.log("Admin layout rendered");
  console.log("Current path:", window.location.pathname);

  return (
    <div className="wrapper">
      <AdminNavbar
        toggleChat={toggleChat}
        isChatVisible={isChatVisible}
      />
      <Sidebar />
      <div className="main-panel">
        <Routes>
          <Route 
            path="dashboard/:dashboardId" 
            element={<Dashboard />} 
          />
          <Route 
            path="report/:reportId/page/:pageName" 
            element={<Report />} 
          />
          <Route 
            path="report/:reportId" 
            element={<Report />} 
          />
          <Route 
            path="forecast-adjustments" 
            element={<ForecastAdjustments />} 
          />
          <Route 
            path="/" 
            element={<Navigate to="/dashboard" replace />} 
          />
        </Routes>
      </div>
    </div>
  );
}

Admin.propTypes = {
  isChatVisible: PropTypes.bool
};

export default Admin;
