import React, { useState, useEffect } from "react";
import { getChartOfAccounts } from "../../utils/api";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Table,
  FormGroup,
  Label,
  UncontrolledTooltip
} from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function ForecastAdjustments() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  
  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const data = await getChartOfAccounts();
      console.log(data)
      setAccounts(data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch accounts');
      setLoading(false);
    }
  };

  const updateCustomName = async (accountId, newName) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          customName: newName
        })
      });
      if (response.ok) {
        fetchAccounts();
      }
    } catch (err) {
      setError('Failed to update custom name');
    }
  };

  const toggleBudget = async (accountId) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          canBudget: !account.canBudget
        })
      });
      if (response.ok) {
        fetchAccounts();
      }
    } catch (err) {
      setError('Failed to update budget');
    }
  };

  const toggleActive = async (accountId) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          isActive: !account.isActive
        })
      });
      if (response.ok) {
        fetchAccounts(); // Refresh the list
      }
    } catch (err) {
      setError('Failed to update account');
    }
  };

  const toggleForecast = async (accountId) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          canForecast: !account.canForecast
        })
      });
      if (response.ok) {
        fetchAccounts();
      }
    } catch (err) {
      setError('Failed to update forecast');
    }
  };

  const updateDriver = async (accountId, newDriver) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          driver: newDriver
        })
      });
      if (response.ok) {
        fetchAccounts();
      }
    } catch (err) {
      setError('Failed to update driver');
    }
  };

  const updateDriverMode = async (accountId, newDriverMode) => {
    try {
      const account = accounts.find(a => a.id === accountId);
      const response = await fetch(`/api/chartOfAccounts/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...account,
          driverMode: newDriverMode
        })
      });
      if (response.ok) {
        fetchAccounts();
      }
    } catch (err) {
      setError('Failed to update driver mode');
    }
  };

  const editAccount = (accountId) => {
    // Implementation of editAccount function
  };

  const filteredAccounts = accounts.filter(account => 
    account.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    account.accountNumber.includes(searchTerm)
  );

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <h5 className="title">Forecast Adjustments</h5>
                <div className="filter-section">
                  <Button color="primary" className="mr-2">All Active</Button>
                  <Button color="info" className="mr-2">All Inactive</Button>
                  <Button color="success" className="mr-2">All Can Forecast</Button>
                  <Button color="warning">All Cannot Forecast</Button>
                </div>
                <FormGroup className="mt-3">
                  <Input
                    type="search"
                    placeholder="Search accounts..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </FormGroup>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Account Number</th>
                      <th>Name</th>
                      <th>Custom Name</th>
                      <th>Is Active?</th>
                      <th>Can Budget?</th>
                      <th>Can Forecast?</th>
                      <th>Driver</th>
                      <th>Driver Mode</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAccounts.map((account) => (
                      <tr key={account.id}>
                        <td>{account.accountNumber}</td>
                        <td>{account.accountName}</td>
                        <td>
                          <Input 
                            type="text" 
                            defaultValue={account.customName}
                            onBlur={(e) => updateCustomName(account.id, e.target.value)}
                          />
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input 
                                type="checkbox" 
                                checked={account.isActive}
                                onChange={() => toggleActive(account.id)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input 
                                type="checkbox" 
                                checked={account.canBudget}
                                onChange={() => toggleBudget(account.id)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input 
                                type="checkbox" 
                                checked={account.canForecast}
                                onChange={() => toggleForecast(account.id)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <Input 
                            type="select" 
                            value={account.driver}
                            onChange={(e) => updateDriver(account.id, e.target.value)}
                          >
                            <option value="">Select Driver</option>
                            <option value="FTES">FTES</option>
                            <option value="ROOMS">ROOMS</option>
                            <option value="SALES">SALES</option>
                          </Input>
                        </td>
                        <td>
                          <Input 
                            type="text" 
                            value={account.driverMode}
                            onChange={(e) => updateDriverMode(account.id, e.target.value)}
                          />
                        </td>
                        <td>
                          <Button
                            className="btn-icon"
                            color="info"
                            size="sm"
                            onClick={() => editAccount(account.id)}
                          >
                            <i className="now-ui-icons ui-2_settings-90" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ForecastAdjustments; 