/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { fetchUserAttributes, signOut } from '@aws-amplify/auth';

// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";
import { getRoutes } from "../../routes";
import { getDashboards, getReports, getOrgConfig, getReportPages } from "../../utils/api"; // Import your API functions
// core components
import avatar from "assets/img/ryan.jpg";
import logo from "logo-white.svg";

var ps;

function Sidebar(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const [routes, setRoutes] = React.useState([]);
  const sidebar = React.useRef(null);
  const location = useLocation();
  const [workspaceId, setWorkspaceId] = useState(null);
  const navigate = useNavigate();
  const [userDisplayName, setUserDisplayName] = useState('');


  const logout = () => {
    signOut();
    localStorage.clear();
    navigate('/login');
  };

  React.useEffect(() => {
    const getUserDetails = async () => {
      try {
        const user = await fetchUserAttributes();
        setUserDisplayName(`${user.given_name} ${user.family_name}`);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    getUserDetails();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Get org config and workspace ID
        const orgConfig = await getOrgConfig();
        const workspaceId = orgConfig[0].workspaceId;
        setWorkspaceId(workspaceId);

        // Get dashboards and reports
        const [dashboardsResponse, reportsResponse] = await Promise.all([
          getDashboards(workspaceId),
          getReports(workspaceId)
        ]);

        // If we're at the root dashboard path, redirect to the first dashboard
        if (location.pathname === '/admin/dashboard' && dashboardsResponse.value.length > 0) {
          const defaultDashboardId = dashboardsResponse.value[0].id;
          navigate(`/admin/dashboard/${defaultDashboardId}`);
        }

        console.log('Reports:', reportsResponse.value);

        // Get pages for all reports
        const reportsWithPages = await Promise.all(
          reportsResponse.value.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, workspaceId);
              return {
                ...report,
                pages: pagesResponse.value.map(page => ({
                  name: page.name,
                  displayName: page.displayName
                }))
              };
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return {
                ...report,
                pages: []
              };
            }
          })
        );

        console.log('Reports with pages:', reportsWithPages);

        // Structure routes with collapsible reports and pages
        const initialRoutes = [
          // Dashboards section
          ...dashboardsResponse.value.map(dashboard => ({
            path: `/dashboard/${dashboard.id}`,
            name: dashboard.displayName,
            icon: "now-ui-icons design_app",
            layout: "/admin"
          })),
          // Forecast Adjustments section
          {
            path: "/forecast-adjustments",
            name: "Forecast Adjustments",
            icon: "now-ui-icons business_chart-bar-32",
            layout: "/admin"
          },
          // Reports section
          {
            collapse: true,
            path: "/reports",
            name: "Reports",
            state: "reportsCollapse",
            icon: "now-ui-icons design_bullet-list-67",
            views: reportsWithPages.map(report => ({
              collapse: true,
              path: `/report/${report.id}`,
              name: report.name,
              mini: report.name.substring(0, 2).toUpperCase(),
              state: `report${report.id}Collapse`,
              layout: "/admin",
              views: report.pages.map(page => ({
                path: `/report/${report.id}/page/${page.name}`,
                name: page.displayName,
                mini: page.displayName.substring(0, 2).toUpperCase(),
                layout: "/admin"
              }))
            }))
          }
        ];

        console.log('Final routes structure:', initialRoutes);
        setRoutes(initialRoutes);

        // Initialize collapse states for all levels
        const initialCollapseStates = {
          reportsCollapse: false,
          ...reportsWithPages.reduce((acc, report) => ({
            ...acc,
            [`report${report.id}Collapse`]: false
          }), {})
        };
        setCollapseStates(initialCollapseStates);

      } catch (error) {
        console.error('Error fetching routes data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this runs once on mount

  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return (
          <li key={key}>
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                // Close all states except the one being toggled
                const newCollapseStates = Object.keys(collapseStates).reduce((acc, key) => ({
                  ...acc,
                  [key]: key === prop.state ? !collapseStates[prop.state] : false
                }), {});
                setCollapseStates(newCollapseStates);
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b className="caret" />
              </p>
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">
                {prop.views && prop.views.map((view, viewKey) => {
                  if (view.collapse) {
                    return (
                      <li key={viewKey}>
                        <a
                          href="#"
                          data-toggle="collapse"
                          aria-expanded={collapseStates[view.state]}
                          onClick={(e) => {
                            e.preventDefault();
                            // Navigate to first page if available
                            if (view.views && view.views.length > 0) {
                              const firstPagePath = view.layout + view.views[0].path;
                              navigate(firstPagePath);
                            }
                            // Close all states except the one being toggled and its parent
                            const newCollapseStates = Object.keys(collapseStates).reduce((acc, key) => ({
                              ...acc,
                              [key]: key === view.state ? !collapseStates[view.state] : 
                                     key === prop.state ? true : false
                            }), {});
                            setCollapseStates(newCollapseStates);
                          }}
                        >
                          <span className="sidebar-mini-icon">{view.mini}</span>
                          <span className="sidebar-normal">
                            {view.name}
                            <b className="caret" />
                          </span>
                        </a>
                        <Collapse isOpen={collapseStates[view.state]}>
                          <ul className="nav">
                            {view.views && view.views.map((subView, subViewKey) => (
                              <li 
                                key={subViewKey}
                                className={activeRoute(subView.layout + subView.path)}
                              >
                                <NavLink 
                                  to={subView.layout + subView.path} 
                                  className={({ isActive }) => 
                                    "nav-link" + (isActive ? " active" : "")
                                  }
                                >
                                  <span className="sidebar-mini-icon">{subView.mini}</span>
                                  <span className="sidebar-normal">{subView.name}</span>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      </li>
                    );
                  }
                  return (
                    <li 
                      key={viewKey}
                      className={activeRoute(view.layout + view.path)}
                    >
                      <NavLink 
                        to={view.layout + view.path}
                        className={({ isActive }) => 
                          "nav-link" + (isActive ? " active" : "")
                        }
                      >
                        <span className="sidebar-mini-icon">{view.mini}</span>
                        <span className="sidebar-normal">{view.name}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li 
          key={key}
          className={activeRoute(prop.layout + prop.path)}
        >
          <NavLink 
            to={prop.layout + prop.path}
            className={({ isActive }) => 
              "nav-link" + (isActive ? " active" : "")
            }
          >
            <i className={prop.icon} />
            <p>{prop.name}</p>
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName) ? "active" : "";
  };

  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <a

            className="simple-text logo-mini"
            target="_blank"
            rel="noreferrer"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a

            className="simple-text logo-normal"
            target="_blank"
            rel="noreferrer"
          >
            Lodgeic
          </a>
          <div className="navbar-minimize">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
              <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  {userDisplayName}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  <li>
                    <a href="#" onClick={(e) => e.preventDefault}>
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={(e) => e.preventDefault}>
                      <span className="sidebar-mini-icon">EP</span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}>
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{createLinks(routes)}</Nav>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => {},
};
console.log(Sidebar.defaultProps)

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;